<template>
  <!-- WITH HANWHA -->
  <section class="report_box" role="tabpanel">
    <div class="box_title">
      <h2 class="title">WITH HANWHA PROGRAM</h2>
      <p class="description" v-html="$t('content.programs.with_hanwha.program_desc')">
      </p>
      <p class="description">{{$t('content.programs.with_hanwha.contact')}}
        <a href="mailto:accelerating@dreamplus.asia" class="mail_link">
          accelerating@dreamplus.asia
        </a>
      </p>
    </div>
    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_hanwha.main_program')}}
      </h3>
    </div>
    <ul class="program_list">
      <li class="program_item">
        <img :src="requireAssetsImg('programs/img_program_major1.png')" width="564" height="640">
        <div class="program" data-aos="fade-up">
          <strong class="name">MEET UP</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.main.meetup_desc')">
          </p>
        </div>
      </li>
      <li class="program_item">
        <img :src="requireAssetsImg('programs/img_program_major2.png')" width="564" height="640">
        <div class="program" data-aos="fade-up">
          <strong class="name">POC</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.main.poc_desc')">
          </p>
        </div>
      </li>
      <li class="program_item">
        <img :src="requireAssetsImg('programs/img_program_major3.png')" width="564" height="640">
        <div class="program" data-aos="fade-up" data-aos-delay="500">
          <strong class="name">TENANTS</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.main.tenants_desc')">
          </p>
        </div>
      </li>
      <li class="program_item">
        <img :src="requireAssetsImg('programs/img_program_major4.png')" width="564" height="640">
        <div class="program" data-aos="fade-up" data-aos-delay="500">
          <strong class="name">DEMODAY</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.main.demoday_desc')">
          </p>
        </div>
      </li>
    </ul>

    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_hanwha.program_benefit')}}
      </h3>
    </div>
    <ul class="benefit_list" >
      <li class="benefit_item" data-aos="fade-up">
          <span class="ico_startup"></span>
          <strong class="name">BUSINESS CONNECTING</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.benefit.business_desc')">
          </p>
      </li>
      <li class="benefit_item" data-aos="fade-up" data-aos-delay="200">
          <span class="ico_network"></span>
          <strong class="name">NETWORKING</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.benefit.networking_desc')">
          </p>
      </li>
      <li class="benefit_item" data-aos="fade-up" data-aos-delay="400">
          <span class="ico_marketing"></span>
          <strong class="name">PR & MARKETING</strong>
          <p class="info" v-html="$t('content.programs.with_hanwha.benefit.pr_desc')">
          </p>
      </li>
    </ul>

    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_hanwha.participation_example')}}
      </h3>
    </div>
    <div class="program_join">
      <img :src="requireAssetsImg('programs/bg_program_case.png')" width="1168" height="1139" class="bg_case">
      <div class="join_case">
        <div class="thumb" data-aos="fade-right">
          <img :src="requireAssetsImg('programs/img_program_case1.png')"
               width="420" height="456">
        </div>
        <div class="interview" data-aos="fade-left">
          <strong class="company">{{$t('content.programs.with_hanwha.example.agilesoda.company')}}
          </strong>
          <em class="collabo">{{$t('content.programs.with_hanwha.example.agilesoda.participant')}}
          </em>
          <p class="review" v-html="$t('content.programs.with_hanwha.example.agilesoda.description')">
          </p>
          <div class="name">{{$t('content.programs.with_hanwha.example.agilesoda.ceo')}}</div>
        </div>
      </div>
      <div class="join_case">
        <div class="interview" data-aos="fade-right">
          <strong class="company">{{$t('content.programs.with_hanwha.example.petner.company')}}
          </strong>
          <em class="collabo">{{$t('content.programs.with_hanwha.example.petner.participant')}}
          </em>
          <p class="review" v-html="$t('content.programs.with_hanwha.example.petner.description')">
          </p>
          <div class="name">{{$t('content.programs.with_hanwha.example.petner.ceo')}}</div>
        </div>
        <div class="thumb" data-aos="fade-left">
          <img :src="requireAssetsImg('programs/img_program_case2.png')" width="420" height="456">
        </div>
      </div>
    </div>
  </section>
  <!-- // WITH HANWHA -->
</template>
<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'withHanwha',
  mixins: [
    mixinHelperUtils,
  ],
};
</script>
