<template>
  <!-- WITH PARTNER -->
  <section class="report_box" role="tabpanel">
    <div class="box_title">
      <h2 class="title">WITH PARTNER PROGRAM</h2>
      <p class="description" v-html="$t('content.programs.with_partner.program_desc')">
      </p>
    </div>

    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.partners')}}
      </h3>
    </div>
    <StaggerTransition
      id="partners"
    >
      <div>
        <ul class="partner_list ani-stagger">
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_hyundai.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_eland.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_kyowon.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_yes24.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_kum.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_bayer.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_dhn.png')" width="180" height="94">
          </li>
          <li class="partner_item">
            <img :src="requireAssetsImg('programs/img_partner_kcca.png')" width="180" height="94">
          </li>
        </ul>
      </div>
    </StaggerTransition>

    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.program_benefit')}}
      </h3>
    </div>
    <StaggerTransition
      id="with-hanwha-benefits"
    >
      <div>
        <ul class="benefit_list">
          <li class="benefit_item">
            <span class="ico_startup"></span>
            <strong class="name">BRAND SUPPORT</strong>
            <p class="info" v-html="$t('content.programs.with_partner.benefit.support_desc')">
            </p>
          </li>
          <li class="benefit_item">
            <span class="ico_network"></span>
            <strong class="name">NETWORKING</strong>
            <p class="info" v-html="$t('content.programs.with_partner.benefit.networking_desc')">
            </p>
          </li>
          <li class="benefit_item">
            <span class="ico_investment"></span>
            <strong class="name">INVESTMENT</strong>
            <p class="info" v-html="$t('content.programs.with_partner.benefit.investment_desc')">
            </p>
          </li>
        </ul>
      </div>
    </StaggerTransition>
    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.selection_process')}}
      </h3>
    </div>
    <div>
      <ul class="process_list">
        <li class="process_item" data-aos="fade-right">
          <div class="process">
            <em class="step">STEP01</em>
            <div class="icon ico_document"></div>
            <div class="name" v-html="$t('content.programs.with_partner.process.step01')">
            </div>
          </div>
        </li>
        <li class="process_item"  data-aos="fade-right" data-aos-delay="200">
          <div class="process">
            <em class="step">STEP02</em>
            <div class="icon ico_screening"></div>
            <div class="name" v-html="$t('content.programs.with_partner.process.step02')">
            </div>
          </div>
        </li>
        <li class="process_item"  data-aos="fade-right" data-aos-delay="400">
          <div class="process">
            <em class="step">STEP03</em>
            <div class="icon ico_screening"></div>
            <div class="name" v-html="$t('content.programs.with_partner.process.step03')">
            </div>
          </div>
        </li>
        <li class="process_item"  data-aos="fade-right" data-aos-delay="600">
          <div class="process">
            <em class="step">STEP04</em>
            <div class="icon ico_successful"></div>
            <div class="name" v-html="$t('content.programs.with_partner.process.step04')">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.participation_example')}}
      </h3>
    </div>
    <div class="program_join">
      <img :src="requireAssetsImg('programs/bg_program_case.png')" width="1168" height="1139" class="bg_case">
      <!-- 프로그램 참여 사례 1 : 웰트 -->
      <div class="join_case">
        <div class="thumb" data-aos="fade-right">
          <img :src="requireAssetsImg('programs/img_program_case3.png')" width="420" height="456">
        </div>
        <div class="interview"  data-aos="fade-left">
          <strong class="company">{{$t('content.programs.with_partner.example.welt.company')}}
          </strong>
          <em class="collabo">{{$t('content.programs.with_partner.example.welt.participant')}}
          </em>
          <p class="review" v-html="$t('content.programs.with_partner.example.welt.description')">
          </p>
          <div class="name">{{$t('content.programs.with_partner.example.welt.ceo')}}
          </div>
        </div>
      </div>
      <!-- 프로그램 참여 사례 2 : 코리아스타트업포럼 -->
      <div class="join_case">
        <div class="interview" data-aos="fade-right">
          <strong class="company">{{$t('content.programs.with_partner.example.korea_startup_forum.company')}}
          </strong>
          <em class="collabo">{{$t('content.programs.with_partner.example.korea_startup_forum.participant')}}
          </em>
          <p class="review" v-html="$t('content.programs.with_partner.example.korea_startup_forum.description')">
          </p>
          <div class="name">{{$t('content.programs.with_partner.example.korea_startup_forum.ceo')}}
          </div>
        </div>
        <div class="thumb" data-aos="fade-left">
          <img :src="requireAssetsImg('programs/img_program_case4.png')" width="420" height="456">
        </div>
      </div>
      <!-- 프로그램 참여 사례 3 : 로켓뷰 -->
      <div class="join_case">
        <div class="thumb" data-aos="fade-right">
          <img :src="requireAssetsImg('programs/img_program_case5.png')" width="420" height="456">
        </div>
        <div class="interview" data-aos="fade-left">
          <strong class="company">{{$t('content.programs.with_partner.example.rocketview.company')}}
          </strong>
          <em class="collabo">{{$t('content.programs.with_partner.example.rocketview.participant')}}
          </em>
          <p class="review" v-html="$t('content.programs.with_partner.example.rocketview.description')">
          </p>
          <div class="name">{{$t('content.programs.with_partner.example.rocketview.ceo')}}</div>
        </div>
      </div>
      <!-- 프로그램 참여 사례 4 : 디자이노블 -->
      <div class="join_case">
        <div class="interview" data-aos="fade-right">
          <strong class="company">{{$t('content.programs.with_partner.example.designovle.company')}}
          </strong>
          <em class="collabo">{{$t('content.programs.with_partner.example.designovle.participant')}}
          </em>
          <p class="review" v-html="$t('content.programs.with_partner.example.designovle.description')">
          </p>
          <div class="name">{{$t('content.programs.with_partner.example.designovle.ceo')}}</div>
        </div>
        <div class="thumb" data-aos="fade-left">
          <img :src="requireAssetsImg('programs/img_program_case6.png')" width="420" height="456">
        </div>
      </div>
    </div>

    <div class="box_title">
      <h3 class="sub_title">ALUMNI</h3>
    </div>
    <div class="alumni_area">
      <div class="order_tab" role="tablist">
        <a href="javascript:void(0);" v-for="(tab, index) in alumniTabList" :key="index"
           class="tab"
           role="tab"
           :aria-selected="currentAlumniTab === index ? 'true' : ''"
           @click="onAlumniTabChange(index)"
        >{{tab}}</a>
      </div>
      <div class="alumni_swiper">
        <div class="partner_list">
          <StaggerTransition targets=".card-wrap"
            id="alumni"
            :delay="0"
          >
            <BaseSlider
              ref="alumniSlider"
              :list="chunkedAlumniList"
              :autoplay="false"
              :preloadImages="false"
              @change="onAlumniSliderChange"
            >
              <template v-slot="{ item }">
                <div class="partner ani-stagger" :class="{ 'hide': item.image ===''} "
                     v-for="(subItem, subIndex) in item.subList"
                     :key="subIndex">
                  <div class="card-wrap">
                    <div class="card">
                      <img v-if="subItem.image" :src="requireAssetsImg(subItem.image)"
                           :alt="subItem.name" width="140" height="110"/>
                      <span :class="subItem.image ? 'blind': 'card-logo-text'">{{subItem.name}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </BaseSlider>
          </StaggerTransition>
        </div>
      </div>
    </div>
  </section>
  <!-- // WITH PARTNER -->
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import _ from 'lodash';

export default {
  name: 'Programs',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    StaggerTransition,
    BaseSlider,
  },
  data() {
    return {
      /**
       * ALUMNI 리스트
       * @type {array}
       */
      alumniList: [
        // with ELAND
        {
          name: 'with ELAND',
          subList: [
            { name: 'Linder(히든트랙)', image: 'programs/logo_eland_member1.png' },
            { name: '디자이노블', image: 'programs/logo_eland_member2.png' },
            { name: '디파인드', image: 'programs/logo_eland_member3.png' },
            { name: '로켓뷰', image: 'programs/logo_eland_member4.png' },
            { name: '메이아이', image: 'programs/logo_eland_member5.png' },
            { name: '몬데이터', image: 'programs/logo_eland_member6.png' },
            { name: '소프트런치', image: 'programs/logo_eland_member7.png' },
            { name: '아자스쿨', image: 'programs/logo_eland_member8.png' },
            { name: '와이드필드', image: 'programs/logo_eland_member9.png' },
            { name: '인포그린', image: 'programs/logo_eland_member10.png' },
            { name: '클린그린', image: 'programs/logo_eland_member11.png' },
            { name: '태그바이', image: 'programs/logo_eland_member12.png' },
            { name: '땡큐마켓', image: 'programs/logo_eland_member13.png' },
            { name: '코너마켓', image: 'programs/logo_eland_member14.png' },
            { name: 'PLZ', image: 'programs/logo_eland_member15.png' },
          ],
        },

        // with KOCCA
        {
          name: 'with KOCCA',
          subList: [
            { name: 'NEOCOMIX', image: 'programs/logo_kocca_member1.png' },
            { name: '둥글', image: 'programs/logo_kocca_member2.png' },
            { name: '보이스루', image: 'programs/logo_kocca_member3.png' },
            { name: '브랜뉴테크(비비빅)', image: 'programs/logo_kocca_member4.png' },
            { name: '브레인콜라', image: 'programs/logo_kocca_member5.png' },
            { name: '엑씽크', image: 'programs/logo_kocca_member6.png' },
            { name: '엠랩', image: 'programs/logo_kocca_member7.png' },
            { name: '클린그린', image: 'programs/logo_kocca_member8.png' },
            { name: '마블러스', image: 'programs/logo_kocca_member9.png' },
            { name: '두리번', image: 'programs/logo_kocca_member10.png' },
            { name: '애드히어로', image: 'programs/logo_kocca_member11.png' },
          ],
        },

        // with LOTTE HOMESHOPPING
        {
          name: 'with LOTTE HOMESHOPPING',
          subList: [
            { name: '아키드로우', image: 'programs/logo_lotte_member1.png' },
            { name: '엠랩', image: 'programs/logo_lotte_member2.png' },
          ],
        },

        // with ZERO1NE
        {
          name: 'with ZERO1NE',
          subList: [
            { name: 'EYERIS', image: 'programs/logo_zero1ne_member1.png' },
            { name: '마인드아이', image: 'programs/logo_zero1ne_member2.png' },
            { name: '몬가타', image: 'programs/logo_zero1ne_member3.png' },
            { name: '비트센싱', image: 'programs/logo_zero1ne_member4.png' },
            { name: '솔티드', image: 'programs/logo_zero1ne_member5.png' },
            { name: '애니랙티브', image: 'programs/logo_zero1ne_member6.png' },
            { name: '에이아이트릭스', image: 'programs/logo_zero1ne_member7.png' },
            { name: '피트메디', image: 'programs/logo_zero1ne_member8.png' },
          ],
        },
        // with 라이프스타일 1기
        {
          name: this.$t('content.programs.with_partner.alumni.lifestyle_01'),
          subList: [
            { name: '두잉랩', image: 'programs/logo_ls1_member1.png' },
            { name: '마보', image: 'programs/logo_ls1_member2.png' },
            { name: '마일로', image: 'programs/logo_ls1_member3.png' },
            { name: '삼쩜일사', image: 'programs/logo_ls1_member4.png' },
            { name: '아토머스', image: 'programs/logo_ls1_member5.png' },
            { name: '키튼플래닛', image: 'programs/logo_ls1_member6.png' },
            { name: '티스쿨컴퍼니', image: 'programs/logo_ls1_member7.png' },
          ],
        },
        // 라이프스타일 2기
        {
          name: this.$t('content.programs.with_partner.alumni.lifestyle_02'),
          subList: [
            { name: 'Trost(휴마트컴퍼니)', image: 'programs/logo_ls2_member1.png' },
            { name: '딥메디', image: 'programs/logo_ls2_member2.png' },
            { name: '라이앤캐처스', image: 'programs/logo_ls2_member3.png' },
            { name: '룬랩', image: 'programs/logo_ls2_member4.png' },
            { name: '뤼이드', image: 'programs/logo_ls2_member5.png' },
            { name: '머니매그넷', image: 'programs/logo_ls2_member6.png' },
            { name: '브레인콜라', image: 'programs/logo_ls2_member7.png' },
            { name: '서틴스플로어', image: 'programs/logo_ls2_member8.png' },
            { name: '아자스쿨', image: 'programs/logo_ls2_member9.png' },
            { name: '엑씽크', image: 'programs/logo_ls2_member10.png' },
            { name: '원드롭', image: 'programs/logo_ls2_member11.png' },
            { name: '웨인힐스벤처스 ', image: 'programs/logo_ls2_member12.png' },
            { name: '웰트 ', image: 'programs/logo_ls2_member13.png' },
            { name: '유커넥 ', image: 'programs/logo_ls2_member14.png' },
            { name: '임퍼펙트 ', image: 'programs/logo_ls2_member15.png' },
            { name: '펫나우 ', image: 'programs/logo_ls2_member16.png' },
            { name: '펫트너 ', image: 'programs/logo_ls2_member17.png' },
            { name: '핑거앤 ', image: 'programs/logo_ls2_member18.png' },
            { name: '더매치랩 ', image: 'programs/logo_ls2_member19.png' },
          ],
        },
      ],

      /**
       * 현재 ALUMNI 탭
       * @type {number}
       */
      currentAlumniTab: 0,
    };
  },
  computed: {
    /**
     * ALUMNI 탭 리스트
     * @type {string[]}
     */
    alumniTabList() {
      return this.alumniList.map((item) => item.name);
    },

    /**
     * 분할된 ALUMNI 리스트 (18개 단위로 분할)
     * @type {array}
     */
    chunkedAlumniList() {
      return this.alumniList.reduce((list, item, index) => list.concat(
        _.chunk(item.subList, 18)
          .map((chunkedSubList) => ({
            name: item.name,
            subList: chunkedSubList,
            tabIndex: index,
          })),
      ), []);
    },
  },

  methods: {
    /**
     * ALUMNI 탭 선택 이벤트 처리
     * @param {number} tabIndex
     */
    onAlumniTabChange(tabIndex) {
      if (tabIndex >= 0) {
        const slideIndex = this.chunkedAlumniList
          .findIndex((item) => item.tabIndex === tabIndex);
        this.$refs.alumniSlider.slideTo(slideIndex);
      }
    },

    /**
     * ALUMNI 슬라이더 변경 이벤트 처리
     * @param {number} slideIndex
     */
    onAlumniSliderChange(slideIndex) {
      const { tabIndex } = this.chunkedAlumniList[slideIndex];
      this.currentAlumniTab = tabIndex;
    },
  },
};
</script>
