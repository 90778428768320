<template>
  <main id="content" role="main">
    <section class="visual_intro">
      <div class="visual_wall"
           :style="{ backgroundImage: 'url(' + requireAssetsImg('programs/img_dreamplus_program.png') + ')' }">
      </div>
      <div class="intro_title">
        <strong class="title">BUSINESS ENABLER</strong>
        <strong class="title">DREAMPLUS</strong>
      </div>
    </section>

    <section class="report_group expend">
      <div class="report_tab_box" role="tablist">
        <div class="report_tab" role="presentation" v-for="(tab, index) in reportTabList" :key="index">
          <a href="javascript:void(0);"
             class="tab"
             role="tab"
             :aria-selected="tab.component === tabComponent ? 'true' : ''"
             @click="reportTabClick(tab.component)"
          >{{tab.name}}</a>
        </div>
      </div>
      <component :is="tabComponent"></component>
    </section>
  </main>
</template>
<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import NewWithHanwha from '@/views/programs/NewWithHanwha.vue';
import NewWithPartner from '@/views/programs/NewWithPartner.vue';
import Internship from '@/views/programs/Internship.vue';

export default {
  name: 'NewPrograms',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    NewWithHanwha,
    NewWithPartner,
    Internship,
  },
  mounted() {
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  },
  data() {
    return {
      tabComponent: 'NewWithHanwha',
      reportTabList: [
        {
          name: 'WITH HANWHA',
          component: 'NewWithHanwha',
        },
        {
          name: 'WITH PARTNER',
          component: 'NewWithPartner',
        },
        {
          name: 'INTERNSHIP',
          component: 'Internship',
        },
      ],
    };
  },
  methods: {
    reportTabClick(tab) {
      this.tabComponent = tab;
    },
  },
};
</script>
